import React from 'react';
import { ethers } from 'ethers';
import { notification } from 'antd';

const WalletVerification = ({ address, userProvider, onSuccess }) => {
  const SIGN_MESSAGE = 'Create memberr account';

  const verifySignature = (signature, address) => {
    console.log('verifySignature() :');
    const trustedMessage = SIGN_MESSAGE;
    const signingAddress = ethers.utils.verifyMessage(trustedMessage, signature);

    console.log('trustedMessage        :', trustedMessage);
    console.log('signingAddress        :', signingAddress);
    console.log('address               :', address);
    console.log('verification success  :', signingAddress === address);

    return signingAddress === address;
  };

  const handleVerificationSigning = async () => {
    console.log('now in handleVerificationSigning() :');

    console.log('SIGN_MESSAGE', SIGN_MESSAGE);

    let signature;
    try {
      signature = await userProvider.send('personal_sign', [SIGN_MESSAGE, address]);
      console.log({ signature });
    } catch (err) {
      console.error(err);
      notification.error({
        description: "Couldn't get signature from wallet",
      });
      return;
    }

    let verificationResult;
    try {
      verificationResult = verifySignature(signature, address);
      console.log({ verificationResult });
      onSuccess();
    } catch (err) {
      console.error(err);
      notification.error({
        description: "Couldn't get signature from wallet",
      });
      return;
    }
  };

  return (
    <button
      onClick={handleVerificationSigning}
      className="w-full px-12 py-4 mt-4 text-white text-lg rounded-lg bg-blue-600 disabled:bg-blue-300 hover:bg-blue-500"
    >
      Wallet verifizieren<span className="ml-2">✍</span>
    </button>
  );
};

export default WalletVerification;
