import React from 'react';

export default function Logo() {
  return (
    <div className="text-white text-xl py-2" style={{ letterSpacing: '4px' }}>
      LOGO
    </div>
  );
  /*
    <img
      src="https://imgur.com/logo.png"
      alt="Logo"
      className="h-[70px] w-[70px]"
      style={{
        whiteSpace: 'normal',
        display: 'inline-block',
        verticalAlign: 'middle',
      }}
    />
    */
}
