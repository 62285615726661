import React, { useState } from 'react';
import RampWidget from './RampWidget';
import OnRamprWidget from './OnRamprWidget';
import PaymentModal from './PaymentModal';

export default function ActionPanel({ nftOpenSeaUrl = '', userAddress, toggleShowConfetti }) {
  const [showRampWidget, setShowRampWidget] = useState(false);
  //const [modalIsVisible, setModalIsVisible] = useState(true);

  console.log({ userAddress });

  return (
    <div className="text-white">
      {/*showRampWidget && <OnRamprWidget userAddress={userAddress} />*/}

      {/*
      <RampWidget
        show={showRampWidget}
        userAddress={userAddress}
        onEvent={() => console.log('rampWidgetOnEvent')}
        amount={18}
      />
      */}

      <PaymentModal modalIsVisible={showRampWidget} setModalIsVisible={setShowRampWidget} />

      <button
        onClick={() => {
          setShowRampWidget(!showRampWidget);
          toggleShowConfetti();
        }}
        className="w-full px-12 py-4 text-white text-lg font-bold rounded-lg bg-blue-600 disabled:bg-blue-300 hover:bg-blue-700 transform transition duration-200 hover:scale-105"
      >
        Jetzt sichern 🚀
      </button>

      <div style={{ width: '100%', textAlign: 'center' }}>
        {/*
        <button
          onClick={() => setShowRampWidget(!showRampWidget)}
          style={{ border: '1px solid', borderColor: 'rgb(265, 256, 256, 0.56)', borderRadius: '10px' }}
          className="w-full mt-5 px-12 py-4 cursor-pointer text-center text-white text-lg font-bold bg-black transform transition duration-200 hover:scale-105"
        >
          {showRampWidget ? 'Guthabenpanel verstecken' : 'Guthaben aufladen'}
        </button>
        */}

        {/*
        <button
          onClick={toggleShowConfetti}
          style={{ border: '1px solid', borderColor: 'rgb(265, 256, 256, 0.56)', borderRadius: '10px' }}
          className="w-full mt-5 px-12 py-4 cursor-pointer tetx-center text-white text-lg font-bold transform transition duration-200 hover:scale-105"
        >
          Konfetti verstecken
        </button>
        */}

        {/*
        <a href="https://discord.com/invite/73vMqt7k7H" target="_blank" rel="noreferrer">
          <button
            style={{ border: '1px solid', borderColor: 'rgb(265, 256, 256, 0.56)', borderRadius: '10px' }}
            className="w-full mt-5 px-12 py-4 cursor-pointer text-center text-white text-lg font-bold bg-black transform transition duration-200 hover:scale-105"
          >
            <img style={{ float: 'left', marginTop: '5px', marginRight: '15px' }} src={DiscordIcon} alt="Discord" />
            Join your fellows in our discord
          </button>
        </a>
        */}
      </div>
    </div>
  );
}
