import React, { useState } from 'react';

import { Button, Modal } from '../../sharedComponents';

export default function PaymentModal({ modalIsVisible, setModalIsVisible }) {
  //const [modalIsVisible, setModalIsVisible] = useState(true);

  const showModal = () => {
    setModalIsVisible(true);
  };

  const hideModal = () => {
    setModalIsVisible(false);
  };

  const onAccept = () => {
    hideModal();
  };

  return (
    <Modal title="Checkout" visible={modalIsVisible} showModal={showModal} hideModal={hideModal}>
      <div className="mt-12 mb-5 text-lg">
        <img
          className="rounded-3xl"
          src={'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/d119655b-e5f2-4d60-2281-3f7165263e00/public'}
          alt="Mint Success"
        />

        <p className="mt-4 text-center text-gray-400 text-sm font-bold">Digital Collectible #42</p>

        <p className="mt-8 text-center text-2xl">€ 5,00</p>

        <div class="relative flex py-5 mt-4 items-center">
          <div class="flex-grow border-t border-gray-500"></div>
          <span class="flex-shrink mx-4 text-gray-500">Pay with Google Pay</span>
          <div class="flex-grow border-t border-gray-500"></div>
        </div>
      </div>

      <Button className="bg-green-400 hover:bg-green-500" onClick={onAccept}>
        Purchase
      </Button>
    </Modal>
  );
}
