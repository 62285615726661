import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useWindowSize, useCss } from 'react-use';
import Confetti from 'react-confetti';
// https://www.npmjs.com/package/searchico
//import searchico from 'searchico';

import { Logo, Title } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

import ActionPanel from './ActionPanel';

//const METADATA_JSON = {};
//const metadataCollection = searchico(METADATA_JSON);

// const FETCH_FLIPSIDE_MUTATION = loader('../../../../services/hasura/mutations/postToFlipside.graphql');

export default function StepSuccess({
  usersSelections,
  //
  twitterHandle,
  userWalletDetails,
  address,
  //
  tx,
  userProvider,
  localProvider,
  externalContracts,
  writeContracts,
  gasPrice,
}) {
  console.log({ usersSelections });

  const [showConfetti, setShowConfetti] = useState(true);
  const toggleShowConfetti = () => setShowConfetti(!showConfetti);
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  /*
  const results01 = metadataCollection.find(usersSelections[0]);

  const db2 = searchico(results01);
  const results02 = db2.find(usersSelections[1]);

  const db3 = searchico(results02);
  const results03 = db3.find(usersSelections[2]);

  const db4 = searchico(results03);
  let finalResult = db4.find(usersSelections[3]);

  console.log({ results01 });
  console.log({ results02 });
  console.log({ results03 });

  if (results02.length === 0) {
    finalResult = results01;
  } else if (results03.length === 0) {
    finalResult = results02;
  } else if (finalResult.length === 0) {
    finalResult = results03;
  }

  console.log({ finalResult });
  */

  const blackBackdropClassName = useCss({
    position: 'fixed',
    top: '50%',
    left: '50%',
    height: '95%',
    width: '70%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0,0,0, 0.65)',
    backdropFilter: 'blur(10px)',
    zIndex: 0,
    borderRadius: '10px',

    '@media only screen and (max-width: 900px)': {
      width: '95%',
    },
  });

  return (
    <>
      {showConfetti && <Confetti width={windowWidth} height={windowHeight} />}

      <div id="BlackBackdrop" className={blackBackdropClassName} />

      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="mt-10 py-5 text-center">
          {/*
          <h1 className="mb-5 text-center text-white" style={{ fontSize: '30px' }}>
            ✨ Glückwunsch ✨
          </h1>
          */}
          <Title styleMobile={{ padding: '0 40px' }}>✨ Glückwunsch! ✨</Title>
        </div>
      </Header>

      <Content>
        <div className="px-5" style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%', maxWidth: '460px', justifyContent: 'center' }}>
            <img
              className="rounded-3xl"
              src={'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/d119655b-e5f2-4d60-2281-3f7165263e00/public'}
              alt="Mint Success"
            />

            <div className="my-10">
              <ActionPanel userAddress={address} toggleShowConfetti={toggleShowConfetti} />
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}
