import React, { useState, useEffect } from 'react';

import Background from './Background';
import { PoweredByMemberrTag } from './sharedComponents';

import { Step01, StepDetailsForm, StepSuccess } from './funnelSteps';

export default function Funnel({
  tx,
  externalContracts,
  writeContracts,
  gasPrice,
  address,
  userProvider,
  localProvider,

  walletIsConnected,
  walletOwnershipIsVerified,
  setWalletOwnershipIsVerified,
  accountView,
}) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [usersSelections, setUsersSelections] = useState([]);
  const [usersTwitterHandle, setUsersTwitterHandle] = useState('');
  const [memberrWalletDetails, setMemberrWalletDetails] = useState({
    address: null,
    privateKey: null,
    walletFile: null,
    password: null,
  });

  const addTextToUsersSelections = newSelectionString => {
    const _usersSelections = [...usersSelections, newSelectionString];
    setUsersSelections(_usersSelections);
  };

  console.log({ usersSelections });
  console.log({ currentStepIndex });
  console.log({ externalContracts });

  const goToNextStep = () => {
    setCurrentStepIndex(currentStepIndex + 1);
  };

  useEffect(() => {
    // scroll to top on each step change
    const scrollContainer = document.getElementById('scrollContainer');
    scrollContainer.scrollTop = 0;
  }, [currentStepIndex]);

  const stepsList = [
    {
      view: <Step01 goToNextStep={goToNextStep} addTextToUsersSelections={addTextToUsersSelections} />,
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/aba199b1-dd90-457c-2e6e-3c33c4e13400/public',
    },
    {
      view: (
        <StepDetailsForm
          goToNextStep={goToNextStep}
          //
          address={address || memberrWalletDetails.address}
          memberrWalletDetails={memberrWalletDetails}
          setMemberrWalletDetails={setMemberrWalletDetails}
          userProvider={userProvider}
          accountView={accountView}
          //
          walletIsConnected={walletIsConnected}
          walletOwnershipIsVerified={walletOwnershipIsVerified}
          setWalletOwnershipIsVerified={setWalletOwnershipIsVerified}
        />
      ),
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/aba199b1-dd90-457c-2e6e-3c33c4e13400/public',
    },
    {
      view: (
        <StepSuccess
          usersSelections={usersSelections}
          //
          twitterHandle={usersTwitterHandle}
          memberrWalletDetails={memberrWalletDetails}
          address={address || memberrWalletDetails.address}
          //
          tx={tx}
          userProvider={userProvider}
          localProvider={localProvider}
          externalContracts={externalContracts}
          writeContracts={writeContracts}
          gasPrice={gasPrice}
        />
      ),
      backgroundUrl: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/aba199b1-dd90-457c-2e6e-3c33c4e13400/public',
    },
  ];

  const activeStepView = stepsList[currentStepIndex].view;

  return (
    <>
      <Background image={stepsList[currentStepIndex].backgroundUrl} />

      {/*
      <div
        id="BlackOverlay"
        style={{
          position: 'fixed',
          zIndex: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0,0,0, 0.55)',
        }}
      />
      */}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          minHeight: '100vh',
          fontFamily: 'RalewayHeavy',
          zIndex: 2,
        }}
      >
        {activeStepView}

        <div style={{ textAlign: 'center', marginTop: '10%', padding: '45px', fontFamily: 'Arial' }}>
          <PoweredByMemberrTag />
        </div>
      </div>
    </>
  );
}
