import React, { useState } from 'react';
import { Form, notification } from 'antd';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useCss } from 'react-use';

import { Logo, Title, Button } from '../../sharedComponents/';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents/';

import WalletVerification from './WalletVerification';
import CreateWallet from './CreateWallet';

const INSERT_LEAD_MUTATION = loader('../../../../services/hasura/mutations/insertLead.graphql');

export default function StepDetailsForm({
  goToNextStep,
  //
  address,
  memberrWalletDetails,
  setMemberrWalletDetails,
  userProvider,
  accountView,
  //
  walletIsConnected,
  walletOwnershipIsVerified,
  setWalletOwnershipIsVerified,
}) {
  const [showCreateWalletFlow, setShowCreateWalletFlow] = useState(false);
  const [emailUserInput, setEmailUserInput] = useState('');

  const [form] = Form.useForm();

  const [insert_leads_one, { data, loading, error }] = useMutation(INSERT_LEAD_MUTATION);

  const onVerificationSuccess = () => {
    setWalletOwnershipIsVerified(true);
  };

  const handleSubmit = async () => {
    const values = await form.validateFields();

    const variables = {
      lead: {
        ...values,
        address,
        customerId: process.env.REACT_APP_CUSTOMER_ID,
      },
    };

    console.log('insert_leads_one', variables);

    const result = await insert_leads_one({ variables });
    const {
      data: {
        insert_leads_one: { id, error },
      },
    } = result;

    if (error) {
      notification.error({
        message: 'Login Error',
        description: error,
        placement: 'topRight',
      });
    }

    if (!error) {
      console.log('success');
      goToNextStep();
    }
  };

  const className = useCss({
    minWidth: '40%',
    maxWidth: '460px',
    padding: '40px 70px',
    backdropFilter: 'blur(10px)',

    '@media only screen and (max-width: 600px)': {
      padding: '20px',
      width: '100%',
    },
  });

  console.log({ walletIsConnected, showCreateWalletFlow, address });

  const hasMemberrWallet = address && !walletIsConnected;

  return (
    <>
      <Header className="mb-20">
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="mt-10">
          <Title>Account erstellen</Title>
        </div>
      </Header>

      <Content>
        <div className="flex p-5" style={{ justifyContent: 'center' }}>
          <div className={`p-4 bg-black bg-opacity-60 rounded-xl ${className}`}>
            <Form form={form} layout="vertical">
              {!showCreateWalletFlow && (
                <>
                  <label className="block text-white" htmlFor="email">
                    E-Mail
                  </label>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Bitte gib eine korrekte E-Mail Addresse an' }]}
                  >
                    <input
                      onKeyUp={e => setEmailUserInput(e.target.value)}
                      placeholder="beispiel@gmail.com"
                      className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  </Form.Item>
                </>
              )}

              {walletIsConnected && <label className="block text-white">Wallet verbinden</label>}

              {!walletIsConnected && !showCreateWalletFlow && !address && (
                <div className="w-full text-center">
                  <Button onClick={() => setShowCreateWalletFlow(true)}>
                    Account erstellen<span className="ml-2">✍️</span>
                  </Button>
                </div>
              )}

              {!walletIsConnected && !showCreateWalletFlow && !address && (
                <div className="mb-5 mt-5 text-white text-sm text-center">oder</div>
              )}

              {!showCreateWalletFlow && accountView}

              {walletIsConnected && !walletOwnershipIsVerified && (
                <div className="w-full mt-2">
                  <WalletVerification
                    address={address}
                    userProvider={userProvider}
                    onSuccess={() => onVerificationSuccess()}
                  />
                </div>
              )}

              {walletOwnershipIsVerified && (
                <div className="w-full mt-5">
                  <label className="block text-white" htmlFor="walletVerification">
                    Wallet verifizieren
                  </label>
                  <input
                    disabled
                    value="✅ Wallet verified"
                    className="w-full px-4 py-2 mt-2 border rounded-md border-green-500 bg-green-100 text-green-800 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  />
                </div>
              )}

              {hasMemberrWallet && (
                <div className="w-full mt-5">
                  <label className="block text-white" htmlFor="walletVerification">
                    Deine Account
                  </label>
                  <input
                    disabled
                    value={`📖 ${address}`}
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none bg-white"
                  />
                </div>
              )}

              {(walletOwnershipIsVerified || memberrWalletDetails?.address) && (
                <div className="flex items-baseline justify-between mt-5">
                  <Button onClick={handleSubmit}>
                    Collectible sichern<span className="ml-2">👉</span>
                  </Button>
                </div>
              )}
            </Form>

            {showCreateWalletFlow && !address && (
              <CreateWallet
                emailUserInput={emailUserInput}
                setMemberrWalletDetails={setMemberrWalletDetails}
                setShowCreateWalletFlow={setShowCreateWalletFlow}
              />
            )}
          </div>
        </div>
      </Content>

      <ContentFooter></ContentFooter>

      <Footer className="flex" style={{ justifyContent: 'center' }}></Footer>
    </>
  );
}
