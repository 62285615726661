import React from 'react';

import { Logo, Title } from '../../sharedComponents';
import { Header, Content, ContentFooter, Footer } from '../../LayoutComponents';
import OptionCard from './OptionCard';

const OPTIONS = [
  {
    image: 'https://imagedelivery.net/x8zI8NzVdLIwGUiottC6wg/d119655b-e5f2-4d60-2281-3f7165263e00/public',
    color: 'blue',
    text: 'Blue cloud',
  },
];

export default function Step01({ addTextToUsersSelections, goToNextStep }) {
  const onOptionSelect = selectedText => {
    addTextToUsersSelections(selectedText);
    goToNextStep();
  };

  return (
    <>
      <Header>
        <div
          className="bg-black bg-opacity-60"
          style={{
            margin: 'auto',
            width: '100px',
            border: '2px solid rgba(0,0,0, 0.6)',
            borderTop: 'none',
            padding: '10px',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            borderRadius: '0 0 1rem 1rem',
          }}
        >
          <Logo />
        </div>
        <div className="my-10">
          <Title>Jetzt mitmachen</Title>
        </div>
      </Header>

      <Content>
        <div
          id="centeredCardsContainer"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignContent: 'flex-center',
            gap: '10px',
          }}
        >
          {OPTIONS.map(({ image, color, text, fontStyle }) => (
            <OptionCard
              image={image}
              color={color}
              text={text}
              fontStyle={fontStyle}
              onClick={() => onOptionSelect(text)}
            />
          ))}
        </div>
      </Content>

      <Footer className="flex" style={{ justifyContent: 'center' }}>
        {/*
        <div className="flex" style={{ maxWidth: '1050px', justifyContent: 'center' }}>
          <img
            src=""
            alt=""
            style={{
              alignSelf: 'flex-end',
              width: '40%',
              height: 'auto',
              marginLeft: '5%',
            }}
          />
          <SpeechBubble
            bubblePointerLeft={true}
            style={{
              alignSelf: 'flex-start',
              marginLeft: '15%',
              marginRight: '5%',
              marginBottom: '5%',
              marginTop: '5%',
            }}
          >
            <div className="p-5 text-left text-white text-xl md:text-2xl">
              <p>Yo.</p>
            </div>
          </SpeechBubble>
        </div>
        */}
      </Footer>
    </>
  );
}
